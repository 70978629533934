import React from 'react'
import Footer from '../Footer'

function Digital() {
    return (
        <>
            <div className='home_area home_page_img'>

                <div className='about_home'>
                    <h1 className='about_us' data-aos="zoom-in" data-aos-duration="500">Digital Marketing</h1>
                </div>
            </div>
            <section className='course_section'>
                <div className="container">
                    <div className="about_row">
                        <div className="about_img">

                            <img src="./img/digital1.png" alt="" className='img'/>
                        </div>
                        <div className="about_details">
                            <h2>Digital Marketing</h2>
                            <p>Our digital marketing course covers SEO, social media marketing, email marketing, and more, equipping you with the tools to succeed in the competitive online landscape.</p>
                            <button className='button'>Inquiry Now</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='courses_icon'>
                <div className='container'>
                    <ul className='courses_icon_img'>
                        <li><img src="./img/digital2.png" alt="" /><p>CMS Hub</p></li>
                        <li><img src="./img/digital3.jpg" alt="" /><p>Canvas</p></li>
                        <li><img src="./img/digital4.png" alt="" /><p>Semrush</p></li>
                        
                    </ul>
                  
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Digital
