import React from 'react'
import { Link } from 'react-router-dom'
import { TiThMenu } from "react-icons/ti";
import { IoClose } from "react-icons/io5";
function Navbar() {

    const clickmenu = () => {
        document.getElementById("nav").style.display = "block";
    }
    const clickclose = () => {
        document.getElementById("nav").style.display = "none"
    }
    return (
        <>
            <header>
                <div className='responsive_header'>
                    <div className="logo">
                        <img src="./img/logo.png" alt="" />
                        <Link to="/"><h5>DGMultimedia</h5></Link>
                    </div>
                    <div className='menubar' onClick={clickmenu}>
                        <h5><TiThMenu /></h5>
                    </div>
                </div>
                <nav id='nav'>
                    <div onClick={clickclose} className='menubar close'>
                        <h5><IoClose /></h5>
                    </div>
                    <ul>
                        <li onClick={clickclose}><Link to="/">Home</Link></li>
                      
                        <li className='dropdown' onClick={clickclose}><Link >Courses</Link>
                            <ul className='dropdown_content'>
                            <li onClick={clickclose}>
                                    <Link to="ui_ux">UI/UX Design</Link>
                                </li>
                                <li onClick={clickclose}>
                                    <Link to="graphics">Graphics Design</Link>
                                </li>
                                <li onClick={clickclose}>
                                    <Link to="java">C, C++, Java</Link>
                                </li>
                            <li onClick={clickclose}>
                                    <Link to="fronted">Frontend Developer</Link>
                                </li>
                                  <li onClick={clickclose}>
                                    <Link to="backend">Backend Developer</Link>
                                </li>
                                <li onClick={clickclose}>
                                    <Link to="full_stack">Full Stack Development</Link>
                                </li>
                                <li onClick={clickclose}>
                                    <Link to="flutter">Flutter Development</Link>
                                </li>
                                <li onClick={clickclose}>
                                    <Link to="digital">Digital Marketing</Link>
                                </li>
                                {/* <li onClick={clickclose}>
                                    <Link to="flutter">Flutter Development</Link>
                                </li> */}
                                {/* <li onClick={clickclose}>
                                    <Link to="full_stack">Full Stack Development</Link>
                                </li> */}
                                {/* <li onClick={clickclose}>
                                    <Link to="ui_ux">UI/UX Design</Link>
                                </li> */}
                                {/* <li onClick={clickclose}>
                                    <Link to="graphics">Graphics Design</Link>
                                </li> */}
                                {/* <li onClick={clickclose}>
                                    <Link to="fronted">Frontend Developer</Link>
                                </li> */}
                                {/* <li onClick={clickclose}>
                                    <Link to="backend">Backend Developer</Link>
                                </li> */}
                                {/* <li onClick={clickclose}>
                                    <Link to="java">C, C++, Java</Link>
                                </li> */}
                                <li onClick={clickclose}>
                                    <Link to="form"></Link>
                                </li>
                            </ul>
                        </li>
                        <li onClick={clickclose}><Link to="blog">Blog</Link></li>
                        <li onClick={clickclose}><Link to="contact">Contact</Link></li>
                          <li onClick={clickclose}><Link to="about">About</Link></li>

                    </ul>

                </nav>
                <div className='inquiry_button'>

                    <Link to="https://wa.me" target="_blank"><button>Inquiry us</button></Link>
                </div>

            </header>
        </>
    )
}

export default Navbar
