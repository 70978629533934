// import React, { useState } from 'react'
// import emailjs from '@emailjs/browser';

// function Form() {
// const [name,setName] = useState('');
// const [email,setEmail] = useState('');
// const[message,setMessage] = useState('');

// const handlesubmit = (e) => {
//     e.preventDefault()
//     const serviceId = 'service_exdvd5j';
//     const templateId = 'template_h258eeb';
//     const publicKey = 'VmZTvUYOdmMCvb6mU';

//     const templateParams = {
//         from_name:name,
//         from_email:email,
//         to_name:'web wizard',
//         message:message,
// };
// emailjs.send(serviceId,templateId,templateParams,publicKey)
// .then((response) => {
//     console.log("successfully",response);
//     setName('');
//     setEmail('');
//     setMessage('');
// })
// .catch((error) => {
//     console.log("erroe",error)
// })

// }

//   return (
//    <>
//    <form action="" onSubmit={handlesubmit}>
//     <input type="text" placeholder='name'  value={name} onChange={(e) => setName(e.target.value)}/>
//     <input type="email" placeholder='email'  value={email} onChange={(e) => setEmail(e.target.value)}/>
//   <textarea name="" id="" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
//   <button type="submit">send email</button>
//    </form>
//    </>
//   )
// }

// export default Form

import React, { useState } from 'react'
import emailjs from '@emailjs/browser';

function Form() {
    const [text, setText] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [select, setSelect] = useState('');
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const serviceId = 'service_mmzkyv5';  
      const templateId = 'template_12fyfdp';  
      const publicKey = 'BtugaOWU0dMxUXodO'; 
  
      const templateParams = {
        from_name: text,
        from_email: email,
        from_number: number,
        to_name: 'Gold Shine',
        message: select,
      };
  
      emailjs.send(serviceId, templateId, templateParams, publicKey)
        .then((response) => {
          console.log("Email sent successfully", response);
          setText('');
          setNumber();
          setEmail('');
          setSelect('');
        })
        .catch((error) => {
          console.error("Error sending email", error);
        });
    };
  
    return (
        <>
           <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Enter Your Name"
              value={text}
              onChange={(e) => setText(e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Enter Your Number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="Enter Your Mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <select
              value={select}
              onChange={(e) => setSelect(e.target.value)}
              required
            >
              <option value="">Select Course</option>
              <option value="Jewellery Design">Jewellery Design</option>
              <option value="Digital Print Design">Digital Print Design</option>
              <option value="Embroidery Design">Embroidery Design</option>
              <option value="Jacquard Design">Jacquard Design</option>
            </select>
            <div className="buttonsec">
              <button type="submit" className="submit">Submit</button>
            </div>
          </form>
      
        </>
    )
}

export default Form

