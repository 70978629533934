import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";


function Footer() {

  return (
    <>
      <footer>
        <div className="container">
          <div className="footer_row">
            <div className="footer_row_col">
              <div className="logo">
                <img src="./img/logo.png" alt="" />
                <h5>DGMultimedia</h5>
              </div>
              <div className="footer_icon">
                <ul>
                  <li>
                    <Link>
                      <FaFacebook />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <IoLogoInstagram />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <FaYoutube />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <FaLinkedinIn />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="footer_nav footer_row_col" >
              <h5 className="footer_title">Menu</h5>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link >Courses</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>

            <div className='contact_details footer_row_col'>
              <h5 className="footer_title">Reach Us</h5>
              <div className="contact_info">
                <IoCall className="icon"/>
                <div>
                  <h6>Phone:</h6>
                 <Link to="tel:+91 91047 90039"> <p>+91 91047 90039</p></Link>
                </div>
              </div>
              <div className="contact_info" >
                <MdEmail className="icon"/>
                <div>
                  <h6>Email:</h6>
                  <Link to="mailto:digimultimedia39@gmail.com"><p>digimultimedia39@gmail.com</p></Link>
                </div>
              </div>
              <div className="contact_info">
                <FaLocationDot className="icon" />

                <div>
                  <h6>Address:</h6>
                  <Link to="https://www.google.com/maps/place/Dg+Multimedia+Education+Institute/@21.1754862,72.8771458,17z/data=!3m1!4b1!4m6!3m5!1s0x3be04fc87b5fdfcb:0x2d136e6092ed978a!8m2!3d21.1754862!4d72.8771458!16s%2Fg%2F11y4knfw3m?entry=ttu"><p>301 , Madhav Shopping Center,  Gyanjyot <br /> School Near, Devadh Canal Road, <br /> Godadra, Surat.</p></Link>
                </div>
              </div>
              {/* <div>
                <h6>Follow Us:</h6>

                <FaFacebook />


                <IoLogoInstagram />

              </div> */}
            </div>
          </div>
        </div>


      </footer>
      <div className="copyright">
        <p>Copyright © 2024 DGMultimedia</p>
      </div>
    </>
  );
}

export default Footer;
