import React from 'react'
import Footer from './Footer'
import { FaUsers } from "react-icons/fa";
import { PiDesktopTowerBold } from "react-icons/pi";
import { GiTeacher } from "react-icons/gi";
import { FaHandsHoldingChild } from "react-icons/fa6";
import { FaListCheck } from "react-icons/fa6";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { GiPencilBrush } from "react-icons/gi";
function Home() {
    const options = {
        items: 3,
        loop: true,
        margin: 20,
        autoplay: true,
        slideTransition: "linear",
        autoplayTimeout: 4000,
        // autoplaySpeed: 3000,
        autoplayHoverPause: false,
        // animateOut:'slideOutUp',
        nav: false,
        dots: false,
        margin: 0,
        responsive: {
            1100: {
                items: 3,
            },
            724: {
                items: 2,
            },
            500: {
                items: 1,
            },
            370: {
                items: 1,
                // innerWidth: "100%",
                // outerWidth: "100%",
            },
            220: {
                items: 1,
            }
        },
    };
    return (
        <>
            <section className='herosection '>
                <div className='container'>
                    <div className='herosection_row'>
                        <div className='herosection_details' data-aos="fade-right"
                            data-aos-duration="3000">
                            <h1>Empower Your Future with DigiMultimedia</h1>
                            <p>Unlock your potential with our cutting-edge digital marketing and IT courses in Surat. Let your career soar with expert guidance.

                            </p>
                            <button className='button'>Explore Now</button>
                        </div>
                        <div className='herosection_img' data-aos="fade-left"
                            data-aos-duration="3000">
                            <img src="./img/home3.png" alt="" />
                        </div>

                    </div>
                </div>
            </section>
            <section className='services_section'>
                <div className='container'>
                    <div className='services_title'>
                        <h2>Diverse Training Services Offered</h2>
                        <p>From digital marketing to web development, we provide top-notch training in various tech fields to shape your career.</p>
                        <span className='hr'></span>
                    </div>
                    <div className='services_card'>
                        <div className='services_card_details'>
                            <div className='services_img'>
                                <img src="./img/digital1.png" alt="" />
                            </div>
                            <div className='card_details'>
                                <h5>Digital Marketing</h5>
                                <p>Boost your online presence with our comprehensive digital marketing strategies.</p>
                            </div>
                        </div>
                        <div className='services_card_details'>
                            <div className='services_img'>
                                <img src="./img/flutter1.png" alt="" />
                            </div>
                            <div className='card_details'>
                                <h5>Flutter Development</h5>
                                <p>Master the art of developing interactive mobile applications using Flutter technology.</p>
                            </div>
                        </div>
                        <div className='services_card_details'>
                            <div className='services_img'>
                                <img src="./img/full.png" alt="" />
                            </div>
                            <div className='card_details'>
                                <h5>Full Stack Development</h5>
                                <p>Become a proficient full stack developer by mastering front-end and back-end development.</p>
                            </div>
                        </div>
                        <div className='services_card_details'>
                            <div className='services_img'>
                                <img src="./img/graphic.png" alt="" />
                            </div>
                            <div className='card_details'>
                                <h5>Graphic Design</h5>
                                <p>Build a Creative Brands with Creative Designs</p>
                            </div>
                        </div>
                        <div className='services_card_details'>
                            <div className='services_img'>
                                <img src="./img/web_design.png" alt="" />
                            </div>
                            <div className='card_details'>
                                <h5>C, C++, Java</h5>
                                <p>Start Your Powerful Basics with c ,c++, java</p>
                            </div>
                        </div>
                        <div className='services_card_details'>
                            <div className='services_img'>
                                <img src="./img/Web.png" alt="" />
                            </div>
                            <div className='card_details'>
                                <h5>Web Developer</h5>
                                <p>Start your Career as a Web Developer</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="about_row">
                        <div className="about_img">

                            <img src="./img/about1.png" alt="" />
                        </div>
                        <div className="about_details">
                            <h2>About DGmultimedia Institute</h2>
                            <p>DGmultimedia is a premier institute based in Surat, offering specialized courses in digital marketing and IT training to propel your career forward.</p>
                            <p>Founded by industry experts with a passion for nurturing talent and shaping successful futures.</p>
                            <button className='button'>Read More</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='offer_section'>
                <div className="container">
                    <div className='offer_row'>
                        <div className="offer_title">
                            <h2>What We Offer</h2>

                        </div>
                        <div className='offer_card_details'>
                            <div className='offer_card_col'>
                                <div className='offer_card'>
                                    <GiPencilBrush /><h6>Soft Skill Traning By Experts</h6>
                                </div>
                                <div className='offer_card'>
                                    <FaHandsHoldingChild /> <h6> Placement Support</h6>
                                </div>
                                <div className='offer_card'>
                                    <FaUsers /><h6> Traning In Team Enviroment</h6>
                                </div>
                            </div>
                            <div className='offer_card_col'>
                                <img src="./img/offer3.png" alt="" />
                            </div>
                            <div className='offer_card_col'>
                                <div className='offer_card'>
                                    <FaListCheck /> <h6>Day-To-Day Progress Tracking</h6>
                                </div>
                                <div className='offer_card'>
                                    <GiTeacher /> <h6> Experienced Faculties From IT Company</h6>
                                </div>
                                <div className='offer_card'>
                                    <PiDesktopTowerBold /><h6> Traching Methodology as It Industry</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='student_reviews_section'>
                <div className='container'>
                    <div className='student_reviews_title'>
                        <h2>Student Reviews</h2>
                    </div>
                    <OwlCarousel className="owl-theme" {...options}>
                        <div class="item">
                            <div className="stu_details">
                                <img src="./img/user1.jpg" alt="" />

                                <h5>Brian Smith</h5>
                                <span>Web Developer</span>

                                <p>
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi
                                    numquam ea inventore obcaecati similique voluptatibus repellat,
                                    expedita blanditiis quae est, illum soluta aut minima illo,
                                    dolore cumque quo voluptatum. Praesentium.
                                </p>
                            </div>
                        </div>
                        <div class="item">
                            <div className="stu_details">
                                <img src="./img/user2.jpg" alt="" />

                                <h5>Samuel Clark</h5>
                                <span>Web Developer</span>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi
                                    numquam ea inventore obcaecati similique voluptatibus repellat,
                                    expedita blanditiis quae est, illum soluta aut minima illo,
                                    dolore cumque quo voluptatum. Praesentium.
                                </p>
                            </div>
                        </div>
                        <div class="item">
                            <div className="stu_details">
                                <img src="./img/user3.jpg" alt="" />

                                <h5>David Johnson</h5>
                                <span>Web Developer</span>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi
                                    numquam ea inventore obcaecati similique voluptatibus repellat,
                                    expedita blanditiis quae est, illum soluta aut minima illo,
                                    dolore cumque quo voluptatum. Praesentium.
                                </p>
                            </div>
                        </div>
                        <div class="item">
                            <div className="stu_details">
                                <img src="./img/user1.jpg" alt="" />

                                <h5>Brian Smith</h5>
                                <span>Web Developer</span>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi
                                    numquam ea inventore obcaecati similique voluptatibus repellat,
                                    expedita blanditiis quae est, illum soluta aut minima illo,
                                    dolore cumque quo voluptatum. Praesentium.
                                </p>
                            </div>
                        </div>
                        <div class="item">
                            <div className="stu_details">
                                <img src="./img/user2.jpg" alt="" />

                                <h5>Samuel Clark</h5>
                                <span>Web Developer</span>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi
                                    numquam ea inventore obcaecati similique voluptatibus repellat,
                                    expedita blanditiis quae est, illum soluta aut minima illo,
                                    dolore cumque quo voluptatum. Praesentium.
                                </p>
                            </div>
                        </div>
                        <div class="item">
                            <div className="stu_details">
                                <img src="./img/user3.jpg" alt="" />
                                <h5>David Johnson</h5>
                                <span>Web Developer</span>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi
                                    numquam ea inventore obcaecati similique voluptatibus repellat,
                                    expedita blanditiis quae est, illum soluta aut minima illo,
                                    dolore cumque quo voluptatum. Praesentium.
                                </p>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Home
