import React from 'react'
import { BrowserRouter, Routes, Route,HashRouter } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import Course from './pages/Course';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import Navbar from './Navbar';
import Backend from './pages/dropdown_pages/Backend';
import Digital from './pages/dropdown_pages/Digital';
import Fronted from './pages/dropdown_pages/Fronted';
import Full_stack from './pages/dropdown_pages/Full_stack';
import Flutter from './pages/dropdown_pages/Flutter';
import Graphics from './pages/dropdown_pages/Graphics';
import Java from './pages/dropdown_pages/Java';
import Ui_ux from './pages/dropdown_pages/Ui_ux';
import Form from './Form';

function Router() {
  return (
   <>

   <HashRouter>
   <Navbar />
   <Routes>
   <Route path="/" element={<Home />} />
   <Route path="about" element={<About/>}></Route>
   <Route path="course" element={<Course/>}></Route>
   <Route path="blog" element={<Blog/>}></Route>
   <Route path="contact" element={<Contact/>}></Route>
   <Route path="backend" element={<Backend/>}></Route>
   <Route path="digital" element={<Digital/>}></Route>
   <Route path="flutter" element={<Flutter/>}></Route>
   <Route path="fronted" element={<Fronted/>}></Route>
   <Route path="full_stack" element={<Full_stack/>}></Route>
   <Route path="graphics" element={<Graphics/>}></Route>
   <Route path="java" element={<Java/>}></Route>
   <Route path="ui_ux" element={<Ui_ux/>}></Route>
   <Route path="form" element={<Form/>}></Route>


   
   </Routes>
   
   </HashRouter>
   </>
  )
}

export default Router
