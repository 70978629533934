import React from 'react'
import Footer from '../Footer'
import { FaRegCircleCheck } from "react-icons/fa6";

function Graphics() {
    return (
        <>
            <div className='home_area home_page_img'>

                <div className='about_home'>
                    <h1 className='about_us' data-aos="zoom-in" data-aos-duration="500">Graphics Design</h1>
                </div>
            </div>
            <section className='course_section'>
                <div className="container">
                    <div className="about_row">
                        <div className="about_img">

                            <img src="./img/graphic.png" alt=""  />
                        </div>
                        <div className="about_details">
                            <h2>Graphics Design</h2>
                            <p>Graphics Design for Your Creatives Future in Graphics Field. Now , Create Visiting Card, Flax Banner, Web Banner, Logo, Pamphlets, Catalogues, etc.</p>
                            <button className='button'>Inquiry Now</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='courses_icon'>
                <div className='container'>
                    <ul className='courses_icon_img'>
                        <li><img src="./img/photoshop.png" alt="" /><p>Adobe Photoshop</p></li>
                        <li><img src="./img/adobe_illustrator.png" alt="" /><p>Adobe illustrator</p></li>
                        <li><img src="./img/figma.jpg" alt="" /><p>Figma</p></li>
                        <li><img src="./img/adobe_hd.png" alt="" /><p>Adobe XD</p></li>
                        <li><img src="./img/adobe_effect.png" alt="" /><p>Adobe After Effect</p></li>
                    </ul>
                  
                </div>
            </section>
            <section className='subject_section'>
                <div className='container'>

                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Figma
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p> <FaRegCircleCheck /> Wireframe & Lo-fi</p>
                                    <p><FaRegCircleCheck />Type, Pages, Color & Icon</p>
                                    <p>  <FaRegCircleCheck /> Prototyping & Animation Basics</p>
                                    <p> <FaRegCircleCheck />Commenting, Mood Board, Columns </p>
                                    <p><FaRegCircleCheck />HTML Media</p>
                                    <p> <FaRegCircleCheck />Colors  </p>
                                    <p> <FaRegCircleCheck />Text & Fonts (Typography)</p>
                                    <p><FaRegCircleCheck />Drawing</p>
                                    <p>  <FaRegCircleCheck /> Autolayouts & Constraints </p>
                                    <p> <FaRegCircleCheck />Effects </p>
                                    <p><FaRegCircleCheck />Components & Instances</p>
                                    <p> <FaRegCircleCheck />Varients, Variables & Forms  </p>
                                    <p> <FaRegCircleCheck />Prototype Add On</p>
                                    <p><FaRegCircleCheck />Animation & Micro Interactions</p>
                                    <p><FaRegCircleCheck />Teams, Projects, Libraries, Thumbnails & Export</p>
                                    <p>  <FaRegCircleCheck />HTML head Elements </p>
                                </div>
                            </div>

                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Photoshop
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p> <FaRegCircleCheck />Photoshop Basics</p>
                                    <p><FaRegCircleCheck /> Photoshop Tools</p>
                                    <p>  <FaRegCircleCheck />Image Editing & Creating</p>
                                    <p> <FaRegCircleCheck />Photoshop With Figma </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
       <section>
        <div className="container">
            <div className="div1">box1</div>
            <div className="div2">box2</div>
            <div className="div3">box3</div>
            <div className="div4">box4</div>
        </div>
       </section>
        </>
    )
}

export default Graphics
