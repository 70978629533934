import logo from './logo.svg';
import './App.css';
// import Route from './com/Route';
import Router from './com/Route';
import "aos/dist/aos.css";
import Aos from 'aos';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    Aos.init();
  })
  return (
   <>

   <Router/>
   </>
  );
}

export default App;
